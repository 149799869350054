export default {
  group: [
    {
      name: 'info',
      fields: [
        {
          name: 'head_full_name',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'subject_status',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'code_main',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'code_rnokpp',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'license_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'date_of_decision',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'decision_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'type_of_activity',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'inn_of_licensee',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'financial_support_of_the_tour_operator',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'financial_support_guarantee_amount',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'financial_support_guarantee_expiry_date',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'location_of_the_activity',
          type: 'text',
          column_type: 'full-row',
        },
        {
          name: 'availability_information',
          type: 'text',
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'insurance_block',
      fields: [
        {
          name: 'name_of_insurance',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
  ],
};
