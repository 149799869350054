<template>
  <div class="text-value text-value__container no-underline"
     :class="{
      'text-value_danger': isValueDanger,
    }"
  >
    <span class="text-value__placeholder text-value__label">
      {{ title }}
    </span>
    <div>{{ value }}</div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    isValueDanger: {
      // value of input
      type: Boolean,
      default: false,
    },
    value: {
      // value of input
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '-',
    },
  },
};
</script>

<style lang="sass">
.tooltip
  cursor: pointer
  display: none
  position: absolute
  right: 13px
  top: 0
  width: 330px
  box-shadow: 0px 4px 14px rgb(0 0 0 / 8%)
  border-radius: 12px
  background-color: $white
  z-index: 10
  padding: 20px 10px

.tooltip__text
  color: $gray
  font-size: 12px
  line-height: 24px
  padding: 20px 0

.tooltip__add
  font-size: 14px
  line-height: 24px
  padding: 20px 0 10px 0

.tooltip__text--small
  display: flex
  border-bottom: 1px solid #D5D5D6
  margin: 10px 0
  font-size: 12px
  padding: 10px 0

.tooltip__title
  padding-bottom: 10px
  border-bottom: 1px solid #D5D5D6

.tooltip__input
  padding-bottom: 10px
  border-bottom: 2px solid $gray
  width: 100%

.tooltip__icon, .tooltip__icon-empty
  position: absolute
  top: 0
  right: 0
  cursor: pointer

.tooltip__cross
  position: absolute
  top: 5px
  right: 5px
  cursor: pointer
  height: 20px !important

.tooltip__icon-empty .svg-icon
  height: 25px

.tooltip__btn
  margin: 25px auto
  width: 100%

.active
  display: block

.tooltip__add
  border-top: 2px solid $gray
  margin-top: 15px

.text-value__container
  border-bottom: 2px solid $gray
  // border: solid 1px red
  margin-bottom: 18px
  position: relative
  &.text-value_error
    border-bottom-color: $error_color
  div
    height: 40px
    display: table-cell
    vertical-align: middle

.text-value_disabled
  opacity: 0.5
  .text-value__input
    cursor: not-allowed

.text-value_readonly
  .text-value__input
    pointer-events: none

.text-value_danger
  div
    color: #e8262e

.text-value_inactive

.text-value__input
  width: 100%
  height: 40px
  font-size: 14px
  &::placeholder
    color: $gray

.text-value_active
  border-color: $black
  .text-value__icon-pass.svg-icon
    fill: $black

.textField__input-focused
  border-bottom: 2px solid $black
  position: absolute
  top: 5px
  padding-top: 10px

.text-value__label
  display: block

.text-value__placeholder
  color: $draft
  font-size: 14px
  line-height: 18px
  @include xs
    font-size: 12px
    line-height: 16px

.text-value__icon-pass.svg-icon
  width: 30px
  // height: 100%
  // max-height: 18px
  max-width: 30px
  position: absolute
  bottom: 5px
  right: 0
  // transform: translateY(-50%)
  fill: $gray

.text-value__icon
  width: 18px !important
  margin-right: 10px

.text-value_isIcon
  display: flex
  align-items: center

.text-value_required
  &:after
    content: "*"
    font-size: 1.8rem
    color: $error

.text-value_stars
  border: none

.text-bold
  font-weight: bold
  color: $black

.error-message
  position: absolute
  padding-top: 5px !important
  font-size: 12px
  color: $error_color

.input_stars
  position: relative
  top: -20px
  display: flex
  flex-direction: column
  gap: 12px
  color: #7F7F7F
  font-size: 14px

.input_selector
  position: relative
  top: -20px
  display: flex
  flex-direction: column
  gap: 12px
  color: #7F7F7F
  font-size: 14px
  width: 100%
  z-index: 20

.rate__wrapper
  height: 40px
  display: flex

.no-underline
  border-bottom: none
</style>
