<template>
  <div class="profile__wrapper" v-if="!!getCurSTDInfo">
    <div class="block__back" @click="goBack">
      <svg-icon name="arrow-back" />
      <h2 class="title-h4">{{ $t('modals.back') }}</h2>
    </div>
    <div class="profile__title-block mb-80">
      <h2 class="title-h2 profile__main-title">
        {{ getCurSTDInfo.name }}
      </h2>
    </div>
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="`${item.column_type}`"
        >
          <text-field
            v-if="item.name === 'type_of_activity'"
            v-model="getTypesOfActivity"
            no-underline
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :is-icon="item.type === 'text_icon'"
            :value-mask="item.mask"
            is-read-only
            icon-name="actual"
          />
          <text-field
            v-else-if="item.name === 'availability_information'"
            v-model="getTypesOfAvailability"
            no-underline
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :is-icon="item.type === 'text_icon'"
            :value-mask="item.mask"
            is-read-only
            icon-name="actual"
          />
          <TextValue
            v-else-if="item.name === 'financial_support_guarantee_expiry_date'"
            :is-value-danger="getCurSTDInfo['financial_support_guarantee_expired']"
            :title="$t(`profile.${item.name}`)"
            :value="getCurSTDInfo[item.name]"
          />
          <text-field
            v-else
            v-model="getCurSTDInfo[item.name]"
            no-underline
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :is-icon="item.type === 'text_icon'"
            :value-mask="item.mask"
            is-read-only
            icon-name="actual"
          />
        </div>
      </div>
    </div>
    <div class="contacts__map" @click="openModalOfMap">
      <img src="@/assets/icons/map_blur.png" alt="" />
      <div class="contacts__map-label">
        <div class="contacts__map-icon">
          <svg-icon name="map_google_icon" />
        </div>
        <div class="contacts__map-text">Переглянути розташування туроператора</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import profile from '@/models/landing/tour_operator';
import TextValue from '@/elements/TextValue.vue';

export default {
  components: {
    TextValue,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getSearchResultDataDetails']),
    getModel() {
      return profile;
    },
    getCurSTDInfo() {
      let obj = {};
      if (!!this.getSearchResultDataDetails?.tour_operator) {
        for (let key in this.getSearchResultDataDetails.tour_operator) {
          let val = !!this.getSearchResultDataDetails['tour_operator'][key]
            ? this.getSearchResultDataDetails['tour_operator'][key]
            : '-';
          obj[key] = val;
        }
      }
      return obj;
    },
    getTypesOfActivity() {
      let typesOfActivity = '';
      if (this.getSearchResultDataDetails?.tour_operator) {
        for (let item in this.getSearchResultDataDetails['tour_operator'][
          'type_of_activity'
        ]) {
          typesOfActivity +=
            this.getSearchResultDataDetails['tour_operator'][
              'type_of_activity'
            ][item].title + ' ';
        }
      }
      return typesOfActivity;
    },
    getTypesOfAvailability() {
      let typesOfAvailability = '';
      if (this.getSearchResultDataDetails?.tour_operator) {
        for (let item in this.getSearchResultDataDetails['tour_operator']['availability_information']) {
          typesOfAvailability += this.getSearchResultDataDetails['tour_operator']['availability_information'][item].title + ' ';
        }
      }

      if (typesOfAvailability === '') {
        typesOfAvailability = '-';
      }

      return typesOfAvailability;
    },
  },
  created() {
    let payload = { id: this.$route.params.id, type: this.$route.meta.type };
    this.$store.dispatch('getSearchResultsDetails', payload);
  },

  methods: {
    openModalOfMap() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'map',
        size: 'l',
        props: this.getCurSTDInfo.address.replaceAll(' ', '+'),
      });
    },
    printWindow() {
      window.print();
    },
    goBack() {
      this.$router.push({ name: 'search-results' });
    },
  },
};
</script>

<style lang="sass" scoped>
.profile__title-block
  display: flex
  gap: 2.4rem
  align-items: center

.contacts__map img
  width: 100%
.contacts__map
  position: relative
  cursor: pointer
.contacts__map:hover .contacts__map-text
  text-decoration: underline

.contacts__map-label
  width: 100%
  position: absolute
  display: flex
  align-items: center
  justify-content: center
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  gap: 16px
  @include s
    gap: 8px

.contacts__map-text
  text-align: center
  font-size: 18px
  line-height: 22px
  @include m
    font-size: 14px
    line-height: 18px
  @include s
    font-size: 11px
    line-height: 15px
</style>
